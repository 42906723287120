import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import { toast } from "react-toastify";
import SelectOption from "../../component/SelectOption/SelectOption";
import SkillsBoost from "../../component/SelectOption/SkillsBoost";
import { CartContext } from "../../context/CartContext";
import { ColorRing } from "react-loader-spinner";
import Quests from "./Quests";
import Services from "./Services";

export default function Details() {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCurrentValue, setSelectedCurrentValue] = useState();
  const [skill, setSkill] = useState();
  const [levels, setLevels] = useState(
    Array.from({ length: 99 }, (_, i) => i + 1)
  );
  const [totalPrice, setTotalPrice] = useState("");
  const [expressValue, setExpressValue] = useState(0);

  let params = useParams();
  const {
    token,
    defferenceLvl,
    setDefferenceLvl,
    methodPrice,
    desiredLevel,
    boostMethod,
    setBoostMethod,
    skillName,
    skillId,
    setSkillId,
    boostMethodId,
    setDesiredLevel,
    setBoostMethodId,
  } = useContext(UserContext);
  const { addToCart, apiType, setApiType, game, setGame } =
    useContext(CartContext);

  const getGamesDetails = async (id) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/games/${id}/details`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const apiType = "powerlevel" in data ? "powerlevel" : "quests";
    setApiType(apiType);
    setGame(data);
  };

  const resetValues = () => {
    setSelectedCurrentValue("");
    setBoostMethod(null);
    setApiType(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getGamesDetails(params.id);
      } catch (error) {
        // Handle error
      } finally {
        setIsLoading(false);
        setDefferenceLvl(null);
        setDesiredLevel(null);
      }
    };

    fetchData();
    resetValues();
  }, [params.id, skill]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getGamesDetails(params.id);
      } catch (error) {
        // Handle error
      } finally {
        setIsLoading(false);
        setDefferenceLvl(null);
        setDesiredLevel(null);
        setSkill(null);
      }
    };
    fetchData();
    resetValues();
  }, [params.id]);

  let description = game?.powerlevel?.description;

  function removeClass(targetClass, ...classesToRemove) {
    const buttons = document.querySelectorAll(`.${targetClass}`);
    buttons.forEach((button) => button.classList.remove(...classesToRemove));
  }

  const calculateTotalPrice = async () => {
    if (!skill || !selectedCurrentValue || !desiredLevel) {
      return;
    }

    const currentSkillId = skill?.is_section ? localStorage.getItem('skillId') : skill.id;
    const currentBoostMethodId = boostMethodId || 0;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/calculate-price`,
        {
          params: {
            skill_id: currentSkillId,
            min_level: selectedCurrentValue,
            max_level: desiredLevel,
            boost_method_id: currentBoostMethodId,
            express: expressValue,
            quantity: 1,
          },
        }
      );
      const price = response.data.price;
      setTotalPrice(price);
      return price;
    } catch (error) {
      console.error("Error calculating price", error);
      toast.error("Failed to calculate price. Please try again.");
    }
  };


  useEffect(() => {
    // Calculate the price when the End Level is selected
    if (desiredLevel) {
      calculateTotalPrice();
    }
  }, [desiredLevel, boostMethodId, expressValue]);

  useEffect(() => {
    setBoostMethod(null);
  }, [defferenceLvl]);

  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center w-100 vh-100">
          <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
          />
        </div>
      ) : (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Power Level</title>
            <link rel="canonical" href="http://mysite.com/example" />
          </Helmet>
          <section className="min-vh-100 my-5">
            <div className="container">
              <h1
                className="h4 text-white text-center blue-color mx-auto rounded p-3 fontt"
                style={{ width: "fit-content" }}
              >
                {game?.name}
              </h1>
              <div className="d-flex">
                <ul
                  className="nav nav-pills d-flex gap-2 mb-3 text-white m-auto blue-color2 rounded"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="pills-PowerLvl-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-PowerLvl"
                      type="button"
                      role="tab"
                      aria-controls="pills-PowerLvl"
                      aria-selected="true"
                    >
                      Power Level
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link "
                      id="pills-Quests-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-Quests"
                      type="button"
                      role="tab"
                      aria-controls="pills-Quests"
                      aria-selected="false"
                    >
                      Quests
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link "
                      id="pills-Services-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-Services"
                      type="button"
                      role="tab"
                      aria-controls="pills-Services"
                      aria-selected="false"
                    >
                      Services
                    </button>
                  </li>
                </ul>
              </div>

              <div
                className="tab-content bg-white bg-primary w-100 rounded-4"
                id="pills-tabContent"
              >
                <div
                  className="tab-pane fade show border-0 active"
                  id="pills-PowerLvl"
                  role="tabpanel"
                  aria-labelledby="pills-PowerLvl-tab"
                  tabIndex={0}
                >
                  <div className="row pt-0 bg-white rounded-4 overflow-hidden">
                    <h3 className="h5 mb-2 px-3 py-2 blue-color text-white rounded-top-4">
                      OSRS Power Leveling
                    </h3>
                    <div className="col-lg-6 lightBlue-color ">
                      <div className="d-flex">
                        <img
                          alt={game?.name}
                          src={game?.powerlevel?.image}
                          className="w-50 mb-3 m-auto"
                        />
                      </div>

                      <div
                        className="fs-"
                        dangerouslySetInnerHTML={{ __html: description }}
                      ></div>
                    </div>
                    <div className="col-lg-6">
                      <h6 className="d-block font">Choose Skill To Boost</h6>
                      <div className="d-flex flex-wrap gap-1">
                        {game?.skills.map((skill, index) => (
                          <button
                            exact="true"
                            onClick={(e) => {
                              setExpressValue(0);
                              setSkill(skill);
                              removeClass(
                                "btn-outline-primary",
                                "blue-color",
                                "text-white"
                              );
                              e.target.classList.add(
                                "blue-color",
                                "text-white"
                              );
                              const selectedBoostMethod = skill?.skills
                                ?.flatMap((subSkill) => subSkill.boot_methods)
                                ?.find((method) => method.id === boostMethodId);

                              if (!selectedBoostMethod) {
                                setBoostMethod(null);
                                setBoostMethodId(0); // Set boostMethodId to 0 if no method available
                              } else {
                                setBoostMethod(selectedBoostMethod);
                                setBoostMethodId(selectedBoostMethod.id);
                              }
                            }}
                            className="btn btn-outline-primary"
                            key={`${skill.id}-${index}`}
                          >
                            {skill?.name}
                          </button>
                        ))}
                      </div>

                      <div>
                        <SelectOption
                          levels={levels}
                          selectedCurrentValue={selectedCurrentValue}
                          setSelectedCurrentValue={setSelectedCurrentValue}
                          skill={skill}
                        />
                      </div>

                      <div>
                        <SkillsBoost
                          skill={skill}
                          removeClass={removeClass}
                          setBoostMethodId={setBoostMethodId}
                          onSkillChange={(newSkillId) => {
                            setSkillId(newSkillId);
                            calculateTotalPrice();
                          }}
                        />
                      </div>
                      {desiredLevel ? (
                        <div>
                          <h6 className="mb-0 mt-2 font">Delivery Speed</h6>
                          <input
                            type="checkbox"
                            id="express"
                            name="express"
                            onChange={(e) =>
                              setExpressValue(e.target.checked ? 1 : 0)
                            } // Update state on change
                          />
                          <label for="express" className="m-1">
                            Express
                          </label>
                        </div>
                      ) : null}
                      <hr />
                      <div className="d-flex m-2 justify-content-between align-items-center mt-5 mb-3">
                        <div>
                          {desiredLevel ? (
                            <h4 className="fw-bold text-success">
                              ${totalPrice}
                            </h4>
                          ) : null}
                        </div>
                        <button
                          onClick={async () => {
                            const calculatedPrice = await calculateTotalPrice();
                            addToCart({
                              type: apiType,
                              gameName: game?.name,
                              gameImg: game?.powerlevel?.image,
                              gameId: game?.id,
                              SkillToBoost: skill?.name,
                              skillId: skill?.is_section ? skillId : skill.id,
                              levelStart: selectedCurrentValue,
                              levelDesired: desiredLevel,
                              totalPrice: calculatedPrice,
                              BoostMethod: boostMethod,
                              BoostMethodId: boostMethodId,
                              ChooseSkill: skillName,
                              express: expressValue,
                            });
                          }}
                          className={`btn btn-success ${
                            !desiredLevel ? "disabled" : ""
                          }`}
                          disabled={!desiredLevel}
                        >
                          Add To Cart
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <Quests />

                <Services />
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
}
