import { motion } from "framer-motion";
import styles from "./Transition.module.css";
import logoMain from "../../assets/logomain.png";

const transionVariants = {
  animate: {
    x: "100%",
    width: "100%",
  },
  initial: {
    x: "0%",
    width: "0%",
  },
  exit: {
    x: ["0%", "100%"],
    width: ["0%", "100%"],
  },
};

const Transition = () => {
  return (
    <>
      <motion.div
        className={`position-fixed top-0 bottom-0 right-0 w-100 h-100 z-30 d-flex justify-content-center align-items-center ${styles.darkerBlue}`}
        variants={transionVariants}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ delay: 0.4, duration: 0.6, ease: "easeInOut" }}
      >
        <img className="neon" src={logoMain} alt="BILS-Logo" />
      </motion.div>

      <motion.div
        className={`position-fixed top-0 bottom-0 right-0 w-100 h-100 z-20 ${styles.darkBlue}`}
        variants={transionVariants}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ delay: 0.6, duration: 0.6, ease: "easeInOut" }}
      ></motion.div>

      <motion.div
        className={`position-fixed top-0 bottom-0 right-0 w-100 h-100 z-10 ${styles.lightBlue}`}
        variants={transionVariants}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ delay: 0.8, duration: 0.6, ease: "easeInOut" }}
      ></motion.div>
    </>
  );
};

export default Transition;
