import React, { useContext } from "react";
import { UserContext } from "../../context/UserContext";

const SelectOption = ({
  levels,
  selectedCurrentValue,
  setSelectedCurrentValue,
  skill,
}) => {
  const { setDefferenceLvl, desiredLevel, setDesiredLevel, setBoostMethod } =
    useContext(UserContext);

  const handleDesiredLevelChange = (e) => {
    const selectedDesiredLevel = parseInt(e.target.value, 10);
    setDesiredLevel(selectedDesiredLevel);
    const levelDifference = selectedDesiredLevel - selectedCurrentValue;
    setDefferenceLvl(levelDifference);
  };

  const handleCurrentValueChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedCurrentValue(parseInt(selectedValue, 10));

    if (selectedValue === "") {
      setDefferenceLvl(null);
      setBoostMethod(null);
    }
  };

  return (
    <div>
      {skill && (
        <div className="row gap-3 p-2">
          <div className="col-md-6 row">
            <label htmlFor="numberSelect1" className="col-12">
              <h6 className="font">Start Level</h6>
            </label>
            <select
              id="numberSelect1"
              className="col-12"
              value={selectedCurrentValue}
              onChange={handleCurrentValueChange}
            >
              <option value="">Choose Current Level</option>
              {levels.map((level, idx) => (
                <option key={idx} value={level}>
                  {level}
                </option>
              ))}
            </select>
          </div>
          {selectedCurrentValue && (
            <div className="col-md-6 row">
              <label htmlFor="numberSelect2" className="col-12">
                <h6 className="font">End Level</h6>
              </label>
              <select
                id="numberSelect2"
                className="col-12"
                onChange={handleDesiredLevelChange}
                value={desiredLevel || ""}
              >
                <option value="">Choose Desired Level</option>
                {levels
                  .filter((level) => level > selectedCurrentValue)
                  .map((level) => (
                    <option key={level} value={level}>
                      {level}
                    </option>
                  ))}
              </select>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SelectOption;
