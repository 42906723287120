import { useEffect, useContext } from "react";
import { UserContext } from "../../context/UserContext";

const SkillsBoost = ({ skill, removeClass, setBoostMethodId, onSkillChange }) => {
  const {
    defferenceLvl,
    methodPrice,
    setMethodPrice,
    setBoostMethod,
    setSkillName,
    setSkillId,
  } = useContext(UserContext);

  const handleBoostClick = (boost) => {
    const calculatedPrice = isNaN(boost?.price * defferenceLvl)
      ? 0
      : boost?.price * defferenceLvl;
    setMethodPrice(calculatedPrice);
    setBoostMethod(boost?.name);
    setBoostMethodId(boost?.id);
  };

  const handleSkillName = (skillNames) => {
    setSkillName(skillNames?.name);
  };

  const handleSkillId = (skillId) => {
    localStorage.setItem('skillId',skillId?.id)
    setSkillId(localStorage.getItem('skillId'));
    onSkillChange(localStorage.getItem('skillId')); // Trigger onSkillChange
  };

  // Set the first skill ID by default if skill.is_section is true
  useEffect(() => {
    if (skill?.is_section && skill?.skills?.length > 0) {
      const firstSkill = skill?.skills[0];
      handleSkillName(firstSkill);
      handleSkillId(firstSkill);
    }
  }, [skill]);
  return (
    <div>
      {defferenceLvl ? (
        <div>
          {skill?.is_section ? (
            <>
              <br />
              <h6 className="h6 d-block font">Choose Skill</h6>
              <div className="d-flex flex-wrap gap-2">
                {skill?.skills?.map((skillNames, index) => (
                  <div key={index}>
                    <div className="">
                      <button
                        className="btn btn-outline-success d-flex"
                        onClick={(e) => {
                          handleSkillName(skillNames);
                          handleSkillId(skillNames);
                          removeClass(
                            "btn-outline-success",
                            "bg-success",
                            "text-white"
                          );
                          e.currentTarget.classList.add(
                            "bg-success",
                            "text-white"
                          );
                        }}
                      >
                        {skillNames?.name}
                      </button>
                    </div>
                  </div>
                ))}
              </div>
              <br />
              {skill?.skills?.some(
                (skillNames) => skillNames.boot_methods.length > 0
              ) && (
                <>
                  <h6 className="h6 d-block font">Boost Method</h6>
                  {skill?.skills?.map((skillNames, index) => (
                    <div className="d-flex flex-wrap gap-2" key={index}>
                      {skillNames?.boot_methods.map((boost, index) => (
                        <div className="" key={index}>
                          <button
                            className="btn btnBoost btn-outline-success form-control d-flex justify-content-between"
                            onClick={(e) => {
                              handleBoostClick(boost);
                              removeClass(
                                "btnBoost",
                                "bg-success",
                                "text-white"
                              );
                              e.currentTarget.classList.add(
                                "bg-success",
                                "text-white"
                              );
                            }}
                          >
                            <div className="btnBoost">{boost.name}</div>
                            <div className="btnBoost">${boost?.price}</div>
                          </button>
                        </div>
                      ))}
                    </div>
                  ))}
                </>
              )}
            </>
          ) : (
            <>
              <br />
              {skill?.boot_methods?.length > 0 && (
                <>
                  <h6 className="h6 d-block font">Boost Method</h6>
                  <div className="row g-3">
                    {skill?.boot_methods?.map((methodes, index) => (
                      <div className="col-6" key={index}>
                        <button
                          className="btn btnBoost btn-outline-success form-control d-flex justify-content-between"
                          onClick={(e) => {
                            handleBoostClick(methodes);
                            removeClass("btnBoost", "bg-success", "text-white");
                            e.currentTarget.classList.add(
                              "bg-success",
                              "text-white"
                            );
                          }}
                        >
                          <div className="btnBoost">{methodes.name}</div>
                          <div className="btnBoost">${methodes?.price}</div>
                        </button>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default SkillsBoost;
