import React from "react";
import { Helmet } from "react-helmet";
import bg from "../../assets/White Navy Pink Glitch Simple 404 Not Found Error T-Shirt (1).png";
export default function NotFound() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>NotFound</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <div className="min-vh-100 neon">
        <div className="w-100 d-flex justify-content-center">
          <img className="w-50" src={bg} alt="Page-Not-Found"></img>
        </div>
      </div>
    </>
  );
}
