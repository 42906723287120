import axios from "axios";
import { createContext, useEffect, useState } from "react";
import { toast } from "react-toastify";

export const UserContext = createContext(0);

export default function UserContextProvider({ children }) {
  const [token, setToken] = useState(localStorage.getItem("token"));

  const [defferenceLvl, setDefferenceLvl] = useState();
  const [methodPrice, setMethodPrice] = useState();
  const [findedRange, setFindedRange] = useState();
  const [desiredLevel, setDesiredLevel] = useState();
  const [skillName, setSkillName] = useState();
  const [skillId, setSkillId] = useState();
  const [boostMethod, setBoostMethod] = useState();
  const [boostMethodId, setBoostMethodId] = useState();

  async function sendDataToSignUp(values) {
    try {
      const data = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/auth/register`,
        values
      );
      toast.success("Registration successful!");
      return data;
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred. Please try again.");
      }
      return null;
    }
  }

  useEffect(() => {
    if (token) {
      localStorage.setItem("token", token);
    } else {
      localStorage.removeItem("token");
    }
  }, [token]);

  async function sendDataToLogin(values) {
    const data = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/auth/login`,
      values
    );
    if (data.token) {
      localStorage.setItem("token", data.token);
      setToken(data.token);
      localStorage.removeItem(`cartData_${data.token}`); // Clear cart when logging in with a new account
    }
    return data;
  }

  function logOut() {
    localStorage.removeItem("token");
    setToken(null);
  }

  return (
    <UserContext.Provider
      value={{
        sendDataToSignUp,
        token,
        setToken,
        sendDataToLogin,
        logOut,
        defferenceLvl,
        setDefferenceLvl,
        methodPrice,
        setMethodPrice,
        findedRange,
        setFindedRange,
        desiredLevel,
        setDesiredLevel,
        boostMethod,
        setBoostMethod,
        skillName,
        setSkillName,
        boostMethodId,
        setBoostMethodId,
        skillId,
        setSkillId,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
