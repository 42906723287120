import React from "react";
import videoFile from "../../assets/footer.mp4";
import styles from "./Footer.module.css";


export default function Footer() {
  return (
    <footer className={`HeaderNav neon ${styles.innShadow}  `}>
      <div className=" text-white d-flex align-items-center blue-color2Opicity">
        <div className="container">
          <div className="text-center row align-items-center ">
            <video
              className="m-auto col-md-4 py-2"
              src={videoFile}
              autoPlay
              muted
              playsInline
              loop
            />
            <div className="col-md-4 ">
            <p className="font-footer">&copy; 2024 BILS Services. All rights reserved.</p>
            </div>
            <ul className="col-md-4 list-unstyled d-flex m-0 align-items-center justify-content-center gap-4">
              <li>
                <a
                  href="https://www.facebook.com/bilsservicess/"
                  className="text-decoration-none text-white"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa-brands fa-facebook-f fs-2"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://discord.com/invite/bils07"
                  className="text-decoration-none text-white"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa-brands fa-discord fs-2"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
