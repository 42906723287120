import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./App.css";
import Layout from "./component/Layout/Layout";
import Transition from "./component/Transition/Transition";
import Login from "./pages/Login/Login";
import Register from "./pages/Register/Register";
import Home from "./pages/Home/Home";
import Cart from "./pages/Cart/Cart";
import UserContextProvider from "./context/UserContext";
import NotFound from "./pages/NotFound/NotFound";
import Profile from "./pages/Profile/Profile";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProtectedRoute from "./component/ProtectedRoutes";
import Details from "./pages/Services/Details";
import CartContextProvider from "./context/CartContext";

function App() {
  let routers = createBrowserRouter([
    {
      path: "",
      element: <Layout />,
      children: [
        {
          index: true,
          element: <Home />,
        },
        {
          path: "Cart",
          element: <Cart />,
        },
        { path: "Login", element: <Login /> },
        { path: "Register", element: <Register /> },
        { path: "games/:id/details", element: <Details /> },
        {
          path: "Profile",
          element: (
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          ),
        },
        { path: "*", element: <NotFound /> },
      ],
    },
  ]);
  return (
    <>
      <Transition />
      <UserContextProvider>
        <CartContextProvider>
          <RouterProvider router={routers} />
          <ToastContainer position="top-center" />
        </CartContextProvider>
      </UserContextProvider>
    </>
  );
}

export default App;
