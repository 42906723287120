import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import marko from "../../../src/assets/marco.jpg";
import john from "../../../src/assets/john.jpg";
// import sarah from "../../../src/assets/sarah.jpg";
import chris from "../../../src/assets/chris.jpg";
import Maria from "../../../src/assets/mariaa.jpg";


const TrustPilotReviews = () => {
  var settings = {
    focusOnSelect: true,
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 3,
    speed: 100,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const reviews = [
    {
      name: "John Doe",
      avatar: john,
      title: "Excellent service",
      comment:
        "Had a great experience with this service. Would highly recommend it to others.",
      link: "https://www.trustpilot.com/review/example.com",
    },
    {
      name: "Marco Smith",
      avatar: marko,
      title: "Impressive!",
      comment:
        "I am thoroughly impressed with the level of professionalism and efficiency.",
      link: "https://www.trustpilot.com/review/sample.com",
    },
    {
      name: "Maria Garcia",
      avatar: Maria,
      title: "Highly recommended",
      comment:
        "Highly recommend this service. Great communication and fast delivery.",
      link: "https://www.trustpilot.com/review/demo.com",
    },
    {
      name: "Chris Johnson",
      avatar: chris,
      title: "Great experience",
      comment:
        "Had a great experience using this service. Will definitely use again in the future.",
      link: "https://www.trustpilot.com/review/samplesite.com",
    },
    // {
    //   name: "Sarah Lee",
    //   avatar: sarah,
    //   title: "Exceptional service",
    //   comment:
    //     "Exceptional service provided. Couldn't be happier with the results.",
    //   link: "https://www.trustpilot.com/review/tryout.com",
    // },
  ];

  return (
    <div className="slider-container py-5 bg-white ">
      <h1 className=" text-center text-blue mb-5 fontt">
        Trustpilot Reviews
      </h1>
      <Slider {...settings} className="container ">
        {/* Map through the reviews array to render individual cards */}
        {reviews.map((review, index) => (
          <div
            key={index}
            className="px-3 text-decoration-none pointer"
          >
            <div className="card testimonial-card mt-2 mb-3">
              <div className="card-up aqua-gradient" />
              <div className="avatar mx-auto">
                <img
                  src={review.avatar}
                  className="rounded-circle img-fluid"
                  alt="avatar"
                />
              </div>
              <div className="card-body text-center">
                <h4 className="card-title font-weight-bold">{review.name}</h4>
                <hr />
                <div className="fw-bold fs-5">{review.title}</div>
                <p>
                  <i className="fas fa-quote-left" /> {review.comment}
                </p>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default TrustPilotReviews;
