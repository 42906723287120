import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CartContext } from "../../context/CartContext";
import axios from "axios";
import QuantityInput from "../../component/QuantityInput/QuantityInput";

export default function Services() {
  const [services, setServices] = useState([]);
  const [servicesDetails, setServicesDetails] = useState([]);
  const [selectedBtnIndex, setSelectedBtnIndex] = useState(null);
  const { addToCart } = useContext(CartContext);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedTypeIndex, setSelectedTypeIndex] = useState(null);
  const [typeChildren, setTypeChildren] = useState([]);
  const [selectedBuildType, setSelectedBuildType] = useState(null);
  const [selectedRadio, setSelectedRadio] = useState(null);
  const [selectedItem, setSelectedItem] = useState({
    selectedRadio: null,
    selectedCheckboxes: [],
  });

  const [serviceTotalPrice, setServiceTotalPrice] = useState(0);
  const [quantity, setQuantity] = useState(1);

  const handleRadioChange = (child) => {
    setSelectedRadio(child.id);
    setSelectedItem((prevSelectedItem) => ({
      ...prevSelectedItem,
      selectedRadio: child,
      selectedCheckboxes: [], // Clear selected checkboxes
    }));

    // Uncheck all checkbox inputs
    const checkboxInputs = document.querySelectorAll(".checkbox-input");
    checkboxInputs.forEach((checkbox) => {
      checkbox.checked = false;
    });
  };

  const handleCheckboxChange = (child) => {
    setSelectedItem((prevSelectedItem) => {
      const isAlreadySelected = prevSelectedItem.selectedCheckboxes.includes(child.id);
  
      const updatedCheckboxes = isAlreadySelected
        ? prevSelectedItem.selectedCheckboxes.filter((id) => id !== child.id)
        : [...prevSelectedItem.selectedCheckboxes, child.id];
  
      return {
        ...prevSelectedItem,
        selectedCheckboxes: updatedCheckboxes,
      };
    });
  };
  

  const params = useParams();

  async function getServices() {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/services`
    );
    setServices(data);
  }

  async function showServices(key) {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/services/${key}`
    );
    setServicesDetails(data);
    if (data && data.length > 0) {
      setSelectedType(data[0]);
      setSelectedTypeIndex(0);
      setTypeChildren(data[0].children);
    }
    // Reset service total price when switching services
    setServiceTotalPrice(0);
  }

  useEffect(() => {
    getServices();
  }, []);
  useEffect(() => {
    console.log("Selected Checkboxes: ", selectedItem.selectedCheckboxes);
    console.log("Selected Radio: ", selectedItem.selectedRadio);
  
    let total = 0;
  
    // Add the price of any independent checkboxes (if selected)
    typeChildren[selectedBuildType]?.children.forEach((child) => {
      if (child.type === 'checkbox' && selectedItem.selectedCheckboxes.includes(child.id)) {
        total += parseFloat(child.price) || 0;
      }
    });
  
    // Add the price of the selected build type if applicable
    const buildTypePrice = parseFloat(typeChildren[selectedBuildType]?.price) || 0;
    total += buildTypePrice;
  
    // Add the price of the selected radio option (if any)
    if (selectedItem.selectedRadio) {
      total += parseFloat(selectedItem.selectedRadio.price) || 0;
  
      // Get the children of the selected radio option (checkboxes)
      const selectedRadioChildren = typeChildren[selectedBuildType]?.children.find(
        (child) => child.id === selectedItem.selectedRadio.id
      )?.children;
  
      if (selectedRadioChildren) {
        selectedItem.selectedCheckboxes.forEach((checkboxId) => {
          const checkbox = selectedRadioChildren.find(
            (child) => child.id === checkboxId
          );
          if (checkbox) {
            total += parseFloat(checkbox.price) || 0;
          }
        });
      }
    }
  
    console.log("Total price before quantity: ", total);
  
    setServiceTotalPrice(total * quantity);
  }, [selectedItem, quantity, typeChildren, selectedBuildType]);
  

  const handleBtnClick = (index) => {
    if (selectedBtnIndex !== null) {
      const prevSelectedBtn = document.getElementById(
        `serviceBtn_${selectedBtnIndex}`
      );
      prevSelectedBtn?.classList.remove("bg-success", "text-white");

      setSelectedBuildType(null);
      setSelectedItem({
        selectedRadio: null,
        selectedCheckboxes: [],
      });

      const radioButtons = document.querySelectorAll(".radio-input");
      radioButtons.forEach((radioButton) => {
        radioButton.checked = false;
      });
    }
    const currentSelectedBtn = document.getElementById(`serviceBtn_${index}`);
    currentSelectedBtn?.classList.add("bg-success", "text-white");
    setSelectedBtnIndex(index);
    showServices(services[index].key);

    // Reset quantity when service changes
    setQuantity(1);
  };

  const handleTypeClick = (type, children, index) => {
    setSelectedType(type);
    setSelectedTypeIndex(index);
    setTypeChildren(children);
    setSelectedBuildType(null);
    setServiceTotalPrice(0);
    setSelectedItem({
      selectedRadio: null,
      selectedCheckboxes: [],
    });
    const radioButtons = document.querySelectorAll(".radio-input");
    radioButtons.forEach((radioButton) => {
      radioButton.checked = false;
    });

    // Reset quantity when type changes
    setQuantity(1);
  };

  const handleBuildTypeChange = (index) => {
    // Update the selected build type
    setSelectedBuildType(index);
  
    // Get the children of the selected build type
    const buildTypeChildren = typeChildren[index];
  
    // Set the selected item based on the selected build type
    const firstRadioChild = buildTypeChildren.children.find(
      (child) => child.type === "radio"
    );
  
    setSelectedItem({
      ...buildTypeChildren,
      selectedRadio: firstRadioChild ? firstRadioChild : null,
      selectedCheckboxes: [],
    });
  
    // If there's a radio option, select the first one by default
    if (firstRadioChild) {
      setSelectedRadio(firstRadioChild.id);
    }
  
    // Reset the checkbox inputs
    const checkboxInputs = document.querySelectorAll(".checkbox-input");
    checkboxInputs.forEach((checkbox) => {
      checkbox.checked = false;
    });
  
    // Reset quantity when build type changes
    setQuantity(1);
    
    // Calculate the build type price and update the total price
    const buildTypePrice = parseFloat(buildTypeChildren.price) || 0;
  
    // Update the service total price considering the build type price
    setServiceTotalPrice((prevTotal) => (prevTotal + buildTypePrice) * quantity);
  };
  

  return (
    <div
      className="tab-pane fade show border-0"
      id="pills-Services"
      role="tabpanel"
      aria-labelledby="pills-Services-tab"
      tabIndex={0}
    >
      <div className="row pt-0 bg-white rounded-4 overflow-hidden">
        <h3 className="h5 mb-2 px-3 py-2 blue-color text-white rounded-top-4">
          OSRS Services
        </h3>
        <div className="col-lg-4 lightBlue-color"></div>
        <div className="col-lg-8">
          <h6 className="d-block font">Choose Services</h6>
          <div className="row g-2">
            {services.map((service, index) => (
              <div className="col-md-4" key={index}>
                <div
                  id={`serviceBtn_${index}`}
                  className="btn btn-outline-success w-100"
                  onClick={() => handleBtnClick(index)}
                >
                  <span>{service.name}</span>
                </div>
              </div>
            ))}
          </div>
          {servicesDetails.length > 0 && (
            <div className="row g-2 my-3">
              <h6 className="d-block m-0 font">Choose Type</h6>
              {servicesDetails.map((type, index) => (
                <div className="col-md-4" key={index}>
                  <div
                    id={`typeBtn_${index}`}
                    className={`btn ${
                      selectedType === type
                        ? "btn-success"
                        : "btn-outline-success"
                    } w-100`}
                    onClick={() => handleTypeClick(type, type.children, index)}
                  >
                    <span>{type.name}</span>
                  </div>
                </div>
              ))}
            </div>
          )}
          {selectedType && typeChildren.length > 0 && (
            <div className="row g-2">
              <h6 className="d-block m-0 font">Choose Build</h6>
              {typeChildren.map((build, index) => (
                <div className="col-md-4" key={index}>
                  <label className="radio-label w-100">
                    <input
                      type="radio"
                      name="buildType"
                      value={index}
                      className="radio-input"
                      onChange={() => handleBuildTypeChange(index)}
                    />
                    <div
                      className={`btn btn-outline-success w-100 ${
                        selectedBuildType === index ? "active" : ""
                      }`}
                      onClick={() => handleBuildTypeChange(index)}
                    >
                      <span>{build.name}</span>
                    </div>
                  </label>
                </div>
              ))}
            </div>
          )}

          {selectedBuildType !== null &&
            typeChildren[selectedBuildType]?.children?.length > 0 && (
              <div className="row mt-3">
                {typeChildren[selectedBuildType].children.some(
                  (child) => child.type === "radio"
                ) ? (
                  <>
                    <div className="col-md-12">
                      <h6 className="d-block m-0 font">Radio Options</h6>
                      {typeChildren[selectedBuildType].children
                        .filter((child) => child.type === "radio")
                        .map((child, index) => (
                          <div className="radio-wrapper" key={index}>
                            <input
                              type="radio"
                              id={child.id}
                              name="radio-group"
                              className="radio m-1"
                              checked={selectedRadio === child.id}
                              onChange={() => handleRadioChange(child)}
                            />
                            <label htmlFor={child.id} className="radio-label">
                              {child.name} - ${Number(child.price).toFixed(2)}
                            </label>
                          </div>
                        ))}
                    </div>

                    {selectedRadio && (
                      <div className="col-md-12 mt-3">
                        <h6 className="d-block m-0 font">Checkbox Options</h6>
                        {typeChildren[selectedBuildType].children
                          .filter((child) => child.type === "checkbox")
                          .map((child, index) => (
                            <div className="checkbox-wrapper" key={index}>
                              <input
                                type="checkbox"
                                id={`sub_${child.id}`}
                                className="checkbox-input m-1"
                                onChange={() => handleCheckboxChange(child)}
                              />
                              <label
                                htmlFor={`sub_${child.id}`}
                                className="checkbox-label"
                              >
                                {child.name} - ${Number(child.price).toFixed(2)}
                              </label>
                            </div>
                          ))}
                      </div>
                    )}
                  </>
                ) : (
                  <div className="col-md-12">
                    <h6 className="d-block m-0 font">Checkbox Options</h6>
                    {typeChildren[selectedBuildType].children
                      .filter((child) => child.type === "checkbox")
                      .map((child, index) => (
                        <div className="checkbox-wrapper" key={index}>
                          <input
                            type="checkbox"
                            id={`sub_${child.id}`}
                            className="checkbox-input m-1"
                            onChange={() => handleCheckboxChange(child)}
                          />
                          <label
                            htmlFor={`sub_${child.id}`}
                            className="checkbox-label"
                          >
                            {child.name} - ${Number(child.price).toFixed(2)}
                          </label>
                        </div>
                      ))}
                  </div>
                )}
              </div>
            )}
             {selectedType?.has_quantity && (
            <div className="row mt-3">
              <div className="col-md-12">
                <QuantityInput quantity={quantity} onQuantityChange={setQuantity} />
              </div>
            </div>
          )}

          <hr />

          <div className="d-flex m-2 justify-content-between align-items-center mt-5 mb-3">
            <div>
              {serviceTotalPrice ? (
                <h4 className="fw-bold text-success">
                  ${Number(serviceTotalPrice).toFixed(2)}
                </h4>
              ) : null}
            </div>
            <button
              className="ms-auto btn btn-success"
              disabled={!serviceTotalPrice}
              onClick={() => {
                if (selectedBtnIndex !== null && selectedItem !== null) {
                  const { selectedRadio, selectedCheckboxes } = selectedItem;

                  // Retrieve the serviceId from the selected service details
                  const serviceId = typeChildren[selectedBuildType]?.id;

                  const cartItem = {
                    type: "service",
                    serviceName: services[selectedBtnIndex].name,
                    serviceId: serviceId, // Bind serviceId here
                    serviceType: servicesDetails[selectedTypeIndex]?.name,
                    selectedItem: {
                      selectedRadio: selectedRadio ? selectedRadio : null,
                      selectedCheckboxes: selectedCheckboxes || [],
                    },
                    serviceTotalPrice: serviceTotalPrice,
                    quantity: quantity,
                  };

                  addToCart(cartItem);
                }
              }}
            >
              Add To Cart
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
