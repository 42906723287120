import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import axios from "axios";
import { useQuery } from "react-query";

export default function GamesCard() {
  const { token } = useContext(UserContext);

  const [data, setData] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/games`
        );
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <section className="border-t-2 border-white bg-secondary-subtle Contact text-blue">
      <div className="blue-color3Opicity py-5">
        <div className="container py-5">
          <div className="d-flex justify-content-center">
            <h1 className="mb-5 rounded text-center fontt"> Our Games</h1>
          </div>
          <div className="row g-3 justify-content-center ">
            {data?.map((data) => (
              <div
                className="col-md-6 col-lg-3 mb-4 mb-lg-0 cardGame"
                key={data?.id}
              >
                <Link
                  className="text-decoration-none"
                  to={`/games/${data?.id}/details`}
                >
                  <div className="card bg-white ">
                    <div className="d-flex justify-content-between p-3">
                      <h1 className="lead fw-bolder text-blue mb-0 text-center w-100">
                        {data.name}
                      </h1>
                    </div>
                    <img
                      src={data.image}
                      className="card-img-top p-3"
                      alt={data.name}
                      loading="lazy"
                    />
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
