import React, { useContext, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import logoMain from "../../assets/logomain.png";
import { UserContext } from "../../context/UserContext";
import styles from "./Navbar.module.css";
import axios from "axios";
import { CartContext } from "../../context/CartContext";

export default function Navbar() {
  const { logOut, token } = useContext(UserContext);
  const { cartData } = useContext(CartContext);

  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/games`
        );
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <nav className={`HeaderNav sticky-top neon ${styles.innShadow}`}>
        <div className="navbar navbar-expand-lg sticky-top  blue-color2Opicity">
          <div className="container ">
            <Link className="navbar-brand " to="/">
              <img className="neon" src={logoMain} alt="BILS-Logo" />
            </Link>
            <button
              className="navbar-toggler border-0 "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="fa-solid fa-bars text-white" />
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav me-auto mb-2 mb-lg-0 gap-4 activeLink">
                <li className="nav-item ">
                  <NavLink className="nav-link px-0" to={"/"}>
                    Home
                  </NavLink>
                </li>
                <li className="nav-item dropdownMenu">
                  <Link
                    className="nav-link px-0 dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Games
                  </Link>
                  <ul className="dropdown-menu w-100 Contact ">
                    <div className="d-flex flex-wrap gap-2">
                      {data?.map((data, idx) => (
                        <li key={data?.id}>
                          <Link
                            className="dropdown-item fontt"
                            to={`/games/${data?.id}/details`}
                          >
                            {data.name}
                          </Link>
                        </li>
                      ))}
                    </div>
                  </ul>
                </li>
              </ul>

              <ul className="navbar-nav ms-auto mb-2 mb-lg-0 gap-4 activeLink">
                {token ? (
                  <>
                    <li className="nav-item">
                      <NavLink className="nav-link px-0" to={"/profile"}>
                        Orders
                      </NavLink>
                    </li>
                    <li className="nav-item position-relative">
                      <NavLink className="nav-link px-0" to={"/cart"}>
                        Cart
                      </NavLink>
                      {cartData.length === 0 ? null : (
                        <div className="w-100 badge CartBadge bg-danger position-absolute end-0">
                          {cartData.length}
                        </div>
                      )}
                    </li>
                    <li className="nav-item">
                      <NavLink
                        className="nav-link px-0"
                        to={"/login"}
                        onClick={() => logOut()}
                      >
                        Sign Out
                      </NavLink>
                    </li>
                  </>
                ) : (
                  <>
                    <li className="nav-item position-relative">
                      <NavLink className="nav-link px-0 " to={"/cart"}>
                        Cart
                      </NavLink>
                      {cartData.length === 0 ? null : (
                        <div className="w-75 badge CartBadge bg-danger position-absolute end-0">
                          {cartData.length}
                        </div>
                      )}
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link px-0" to={"/login"}>
                        Login
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link px-0" to={"/register"}>
                        Register
                      </NavLink>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
