import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { CartContext } from "../../context/CartContext";
import { useParams } from "react-router-dom";

const Quests = () => {
  const [quests, setQuests] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortColumn, setSortColumn] = useState(null);

  const { addToCart, game, cartData } = useContext(CartContext);
  let params = useParams();

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchQuests(page, params.id);
  };

  const fetchQuests = async (page, id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/games/${id}/quests?page=${page}`
      );
      setQuests(response.data.data);
      setCurrentPage(response.data.current_page);
      setTotalPages(response.data.last_page);
    } catch (error) {
      console.error("Error fetching quests:", error);
    }
  };

  const handleSearch = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/games/${params.id}/quests?search=${searchQuery}`
      );
      setQuests(response.data.data);
      setCurrentPage(response.data.current_page);
      setTotalPages(response.data.last_page);
    } catch (error) {
      console.error("Error fetching quests:", error);
    }
  };

  useEffect(() => {
    fetchQuests(currentPage, params.id);
  }, [currentPage, params.id]);

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("asc");
    }
  };

  const sortedQuests = () => {
    return [...quests].sort((a, b) => {
      if (sortColumn === "name") {
        return sortOrder === "asc"
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
      } else if (sortColumn === "price") {
        return sortOrder === "asc" ? a.price - b.price : b.price - a.price;
      }
      return 0;
    });
  };

  return (
    <div
      className="tab-pane fade show border-0"
      id="pills-Quests"
      role="tabpanel"
      aria-labelledby="pills-Quests-tab"
      tabIndex={0}
    >
      <div className="row  pt-0 bg-white rounded-4 overflow-hidden">
        <h3 className="h5 mb-2 px-3 py-2 blue-color text-white rounded-top-4">
          OSRS Quests
        </h3>
        <div className="col-lg-4 lightBlue-color "></div>
        <div className="col-lg-8">
          <p className="d-block">Choose Quests</p>
          <div className="input-group">
            <div className="form-outline" data-mdb-input-init>
              <input
                type="search"
                id="form1"
                className="form-control"
                placeholder="Search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <button className="btn btn-primary" onClick={handleSearch}>
              Search
            </button>
          </div>
          <table className="table table-striped text-center">
            <thead>
              <tr>
                <th
                  scope="col"
                  onClick={() => handleSort("name")}
                  className="pointer"
                >
                  Name
                </th>
                <th scope="col">Difficulty</th>
                <th
                  scope="col"
                  onClick={() => handleSort("price")}
                  className="pointer"
                >
                  Price
                </th>
                <th scope="col">Order</th>
              </tr>
            </thead>
            <tbody>
              {sortedQuests().map(
                (quest) =>
                  !cartData.some(
                    (item) =>
                      item.type === "quest" && item.quest_id === quest.id
                  ) && (
                    <tr key={quest.id}>
                      <td className="fw-bold">{quest.name}</td>
                      <td>{quest.difficulty}</td>
                      <td className="">$ {quest.price}</td>
                      <td>
                        <button
                          className="btn btn-info text-white fw-bolder"
                          onClick={() => {
                            addToCart({
                              type: "quest",
                              gameId: quest?.game_id,
                              gameName: game?.name,
                              game_Img: game?.image,
                              quest_Difficulty: quest?.difficulty,
                              quest_id: quest?.id,
                              quest_name: quest?.name,
                              totalPrice: quest?.price,
                            });
                          }}
                        >
                          +
                        </button>
                      </td>
                    </tr>
                  )
              )}
            </tbody>
          </table>
        </div>
        <div className="d-flex">
          <ul className="pagination ms-auto">
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <button
                className="page-link"
                onClick={() => handlePageChange(currentPage - 1)}
              >
                &laquo;
              </button>
            </li>

            {[...Array(totalPages).keys()].map((page) => (
              <li
                key={page + 1}
                className={`page-item ${
                  currentPage === page + 1 ? "active" : ""
                }`}
              >
                <button
                  className="page-link"
                  onClick={() => handlePageChange(page + 1)}
                >
                  {page + 1}
                </button>
              </li>
            ))}

            <li
              className={`page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <button
                className="page-link"
                onClick={() => handlePageChange(currentPage + 1)}
              >
                &raquo;
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Quests;
