import React from "react";
import { Link } from "react-router-dom";

const TrustPilot = () => {
  return (
    <section className="text-blue">

        <Link
          to="https://www.trustpilot.com/review/bilsservices.com"
          target="_blank"
          rel="noopener"
          className="text-white fw-bolder w-100 text-center"
        >
          <img
            src="/trustpilot-3-512.webp"
            alt="Trustpilot Logo"
            className="w-100"
          />
        </Link>
    </section>
  );
};

export default TrustPilot;
