import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { UserContext } from "./../../context/UserContext";
import axios from "axios";
import { useQuery } from "react-query";
import Orders from "../../component/orders/Orders";
import ContactLiveChatButton from "../Cart/ContactLiveChatButton";

export default function Profile() {
  const { token } = useContext(UserContext);
  const [userData, setUserData] = useState();

  async function getProfileData() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/user`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUserData(response.data);
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  }

  useEffect(() => {
    getProfileData();
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Profile</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>

      <section className="min-vh-100">
        <div className="py-5">
          <div className="w-25 m-auto ">
            <div className="">
              <div className="blue-color2 p-3 rounded text-white mb-4 neon">
                <div className="card-body">
                  <div className="d-flex gap-2 flex-wrap">
                    <div className="">
                      <p className="">Name :</p>
                    </div>
                    <div className="">
                      <p className="text-center">{userData?.name}</p>
                    </div>
                  </div>
                  <hr />
                  <div className="d-flex gap-2 flex-wrap ">
                    <div className="">
                      <p className="">Email :</p>
                    </div>
                    <div className="">
                      <p className="text-center">{userData?.email}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ContactLiveChatButton></ContactLiveChatButton>
        <div className="p-2 m-5 bg-white rounded-3 text-center">
          <Orders />
        </div>
      </section>
    </>
  );
}
