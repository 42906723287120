import React, { useState, useEffect } from "react";
import { Button, FormControl, InputGroup } from "react-bootstrap";

const QuantityInput = ({ quantity: initialQuantity, onQuantityChange }) => {
  const [localQuantity, setLocalQuantity] = useState(initialQuantity);

  useEffect(() => {
    setLocalQuantity(initialQuantity);
  }, [initialQuantity]);

  const handleIncrement = () => {
    const newQuantity = localQuantity + 1;
    setLocalQuantity(newQuantity);
    onQuantityChange(newQuantity);
  };

  const handleDecrement = () => {
    if (localQuantity > 1) {
      const newQuantity = localQuantity - 1;
      setLocalQuantity(newQuantity);
      onQuantityChange(newQuantity);
    }
  };

  const handleInputChange = (e) => {
    let value = parseInt(e.target.value, 10);

    if (!isNaN(value) && value > 0) {
      setLocalQuantity(value);
      onQuantityChange(value);
    } else if (e.target.value === "") {
      setLocalQuantity("");
    }
  };

  const handleBlur = () => {
    if (localQuantity === "") {
      setLocalQuantity(1);
      onQuantityChange(1);
    }
  };

  return (
    <InputGroup className="mb-3 w-25">
      <Button
        variant="outline-secondary bg-danger text-white"
        onClick={handleDecrement}
      >
        -
      </Button>
      <FormControl
        className="text-center"
        value={localQuantity}
        onChange={handleInputChange}
        onBlur={handleBlur}
      />
      <Button
        variant="outline-secondary bg-success text-white"
        onClick={handleIncrement}
      >
        +
      </Button>
    </InputGroup>
  );
};

export default QuantityInput;
