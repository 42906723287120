import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/UserContext";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { Modal } from "react-bootstrap";

const Orders = () => {
  const { token } = useContext(UserContext);

  const itemsPerPage = 10; // Change this according to your desired items per page
  const [userData, setUserData] = useState();
  const [orderData, setOrderData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  //# User
  async function getProfileData() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/user`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUserData(response?.data);
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  }

  // # Orders
  async function getUserOrder() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/orders`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setOrderData(response.data);
    } catch (error) {
      console.error("Error fetching order data:", error); // Fix here, change the console message
    }
  }

  useEffect(() => {
    getProfileData();
    getUserOrder();
  }, []);

  const offset = currentPage * itemsPerPage;
  const currentOrders = orderData?.slice(offset, offset + itemsPerPage);
  const pageCount = Math.ceil(orderData?.length / itemsPerPage);

  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null); // State to store modal content

  // @ Modal
  const openModal = (order) => {
    const items = order.items;

    const content = items.map((item, index) => (
      <div
        key={index}
        className="row justify-content-center align-items-center"
      >
        {/* <div className="col-md-6 ">
          <h1 className="h5">{item.game.name}</h1>
          <img
            src={item.game.image}
            alt={item.game.name}
            style={{ maxWidth: "100px", maxHeight: "100px" }}
          />
        </div> */}
        <div className="col-md-12">
          {item.type === "skill" ? (
            <>
              <h4 className="text-center">Power Level</h4>
              <div className="text-primary">
                <p>Price: ${item.price}</p>
                <p>Current Level: {item?.min_level}</p>
                <p>Desired Level: {item?.max_level}</p>
                <p>Skill: {item?.skill.name}</p>
              </div>
            </>
          ) : item.type === "quest" ? (
            <>
              <h4 className="text-center">Quest</h4>
              <div className="text-primary">
                <p>Price: ${item?.price}</p>
                <p>Current Level: {item?.quest.difficulty}</p>
                <p>Desired Level: {item?.quest.name}</p>
              </div>
            </>
          ) : item.type === "service" ? (
            <>
              <h4 className="text-center">Service</h4>
              <div className="text-primary">
                <p>Price: ${item?.price}</p>
                <p> {item?.service.parent.name}</p>
                <p> {item?.service.name}</p>
                <p>Quantity: {item?.quantity}</p>
              </div>
            </>
          ) : null}
        </div>
        <hr />
      </div>
    ));

    setModalContent(content);
    setShowModal(true);
  };

  return (
    <div className="table-container" style={{ overflowY: "auto" }}>
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">Order Id</th>
            <th scope="col">User</th>
            <th scope="col">Status</th>
            <th scope="col">Items Count</th>
            <th scope="col">Original Price</th>
            <th scope="col">Discount Price</th>
            <th scope="col">Total Price</th>
            <th scope="col">Order Details</th>
          </tr>
        </thead>
        <tbody>
          {currentOrders?.map((order, index) => (
            <tr key={index} className="">
              <td className="fw-bold">{order.id}</td>
              <td>{userData?.email}</td>
              <td className={order.status === "pending" ? "text-warning" : ""}>
                {order.status}
              </td>
              <td>{order.items.length}</td>
              <td className="text-success fw-bold">$ {order.original_price}</td>
              <td className="text-success fw-bold">
                {order.discount_price === 0 ? "-" : `$ ${order.discount_price}`}
              </td>
              <td className="text-success fw-bold">$ {order.total_price}</td>
              <td
                className="text-success fw-bold"
                onClick={() => openModal(order)}
              >
                <button className="btn btn-secondary">Details</button>{" "}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="d-flex justify-content-center">
        <ReactPaginate
          previousLabel={<i className="fa fa-chevron-left"></i>}
          nextLabel={<i className="fa fa-chevron-right"></i>}
          breakLabel={<span className="text-muted">...</span>}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          activeClassName={"active"}
        />
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Order Items</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalContent}</Modal.Body>
      </Modal>
    </div>
  );
};

export default Orders;
