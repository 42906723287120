import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import "./register.css";
import logoMain from "../../assets/logomain.png";
import videoFile from "../../assets/yaser.mp4";
import { motion } from "framer-motion";
import { fadeIn } from "../../variants";
import * as Yup from "yup";
import { useFormik } from "formik";
import { UserContext } from "../../context/UserContext";

export default function Register() {
  const { sendDataToSignUp } = useContext(UserContext);

  const navigate = useNavigate();

  const validationSchema = Yup.object({
    name: Yup.string()
      .required("username is required")
      .min(3, "username must be more than 3 characters")
      .max(20, "username must be less than 20 characters"),

    email: Yup.string()
      .required("email is required")
      .email("please enter a valid email"),
    password: Yup.string()
      .required("password is required")
      .matches(
        /^(?=.*[A-Z]).{8,}$/,
        "must contain at least one uppercase and at least 8 characters"
      ),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref("password")], "Passwords do not match")
      .required("Password confirmation is required"),
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      password_confirmation: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const response = await sendDataToSignUp(values);
      if (response.status == 200) {
        navigate("/login");
      }
    },
  });

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Register</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <div className="container d-flex justify-content-center align-items-center min-vh-100">
        <motion.div
          variants={fadeIn("left", 0.2)}
          initial="hidden"
          animate="show"
          exit="hidden"
          className="row border w-75  rounded-5 p-3 bg-white shadow "
        >
          <form className="col-md-6 right-box" onSubmit={formik.handleSubmit}>
            <div className="row align-items-center">
              <div className="header-text headerHello mb-4">
                <h2>Hello</h2>
                <p>We are happy to have you.</p>
              </div>
              <div className="mb-3">
                <input
                  className="form-control emailInput"
                  type="text"
                  placeholder="Name"
                  id="name"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.name && formik.touched.name ? (
                  <div className="alert alert-danger">{formik.errors.name}</div>
                ) : (
                  ""
                )}
              </div>
              <div className="mb-3">
                <input
                  className="form-control emailInput"
                  type="email"
                  placeholder="Email"
                  id="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.email && formik.touched.email ? (
                  <div className="alert alert-danger">
                    {formik.errors.email}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="pass-field mb-3 position-relative">
                <input
                  className="form-control passwordInput"
                  type="password"
                  placeholder="Password"
                  id="password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.password && formik.touched.password ? (
                  <div className="alert alert-danger">
                    {formik.errors.password}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="pass-field mb-5 position-relative">
                <input
                  className="form-control passwordInput"
                  type="password"
                  placeholder="re-Password"
                  id="password_confirmation"
                  name="password_confirmation"
                  value={formik.values.password_confirmation}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.password_confirmation &&
                formik.touched.password_confirmation ? (
                  <div className="alert alert-danger">
                    {formik.errors.password_confirmation}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <p className="text-danger showUpLog" />
              <div className="mb-3">
                <button
                  type="submit"
                  className=" form-control blue-color text-white w-100 fs-6 loginBtn"
                  disabled={!(formik.isValid && formik.dirty)}
                >
                  Login
                </button>
              </div>
            </div>
          </form>
          <div className="slider col-md-6 rounded-4 d-flex justify-content-center blue-color3 align-items-center flex-column left-box">
            <motion.div
              variants={fadeIn("down", 0.4)}
              initial="hidden"
              animate="show"
              exit="hidden"
              className="mb-5 d-flex"
            >
              <video
                src={videoFile}
                className="w-75 m-auto rounded"
                autoPlay
                muted
                playsInline
                loop
              />
            </motion.div>
            <motion.div
              className="text-center"
              variants={fadeIn("up", 0.4)}
              initial="hidden"
              animate="show"
              exit="hidden"
            >
              <p className="text-white fs-2">Be Verified</p>
              <small className="text-white text-wrap text-center mb-3">
                Join experienced Designers on this platform.
              </small>
            </motion.div>
          </div>
        </motion.div>
      </div>
    </>
  );
}
